import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import qualityApi from "service/qualityApi";

import ModalAttendanceAnalysisPrompt from "components/Modal/ModalAttendanceAnalysisPrompt";
import ModalAttendanceAnalysisResponse from "components/Modal/ModalAttendanceAnalysisResponse";
import ModalPromptScoreDetails from "components/Modal/ModalPromptScoreDetails";
import ModalCustomAttendanceAnalysis from "components/Modal/ModalCustomAttendanceAnalysis";
import Loading from "components/Loading";
import { ReactComponent as YellowFlag } from "assets/icons/flag_yellow_icon.svg";
import { ReactComponent as RedFlag } from "assets/icons/flag_red_icon.svg";
import ModalUserAttendanceFeedback from "components/Modal/ModalUserAttendanceFeedback";
import ModalAttendanceReview from "components/Modal/ModalAttendanceReview";
import ModalContestationForm from "components/Modal/ModalContestationForm";
import ModalContestationStatus from "components/Modal/ModalContestationStatus";
import ModalPromptRulesDetails from "components/Modal/ModalPromptRulesDetails";
import { ReactComponent as CheckIcon } from "assets/icons/check_icon.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross_icon.svg";
import AudioPlayer from "components/AudioPlayer";

const QualityAttendance = () => {
  const { id: attendanceId } = useParams();
  const [attendance, setAttendance] = useState({});
  const [attendanceName, setAttendanceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [score, setScore] = useState(null);
  const [segmentID, setSegmentID] = useState();
  const [evaluatedHumanPrompt, setEvaluatedHumanPrompt] = useState([]);
  const [evaluableEvent, setEvaluableEvent] = useState([]);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [orderPromptsBy, setOrderPromptsBy] = useState([]);
  const permissions = localStorage.getItem("permissions");
  const [hasFeedback, setHasFeedback] = useState(false);
  const [hasReview, setHasReview] = useState(false);
  const [promptsPriority, setPromptsPriority] = useState([]);
  const [evaluableEventPriority, setEvaluableEventPriority] = useState([]);
  const [contestation, setContestation] = useState([]);
  const [scoreRule, setScoreRule] = useState([]);
  const questionRegex = /<pergunta>(.*?)<pergunta>/;

  useEffect(() => {
    if (
      attendanceId &&
      permissions.includes("create:quality:user_attendance_feedback")
    ) {
      qualityApi
        .get(`/user-attendance-feedback/${attendanceId}`)
        .then((response) => response.data)
        .then((response) => {
          if (response.length === 0) {
            setHasFeedback(false);
          }
          if (
            response?.[0]?.status === "PENDING" ||
            response?.[0]?.status === "APPROVED"
          ) {
            setHasFeedback(true);
          }
        })
        .catch((e) => {
          toast.error("Erro ao recuperar as informações de feedback");
        });
    }
  }, [attendanceId, updateAttendance]);

  useEffect(() => {
    if (
      attendanceId &&
      permissions.includes("create:quality:attendance_review")
    ) {
      qualityApi
        .get(`/attendance-review/${attendanceId}`)
        .then((response) => response.data)
        .then((response) => {
          setHasReview(response.length !== 0);
        })
        .catch((e) => {
          toast.error(
            "Erro ao recuperar informações de revisão do atendimento",
          );
        });
    }
  }, [attendanceId, updateAttendance]);

  useEffect(() => {
    setLoading(true);

    qualityApi
      .get(`/attendances/${attendanceId}`)
      .then((response) => response.data)
      .then((response) => {
        const aIPrompt = response.prompts.filter((p) => p.type === "AI");
        const humanPrompt = response.prompts.filter((p) => p.type === "HUMAN");
        setAttendance(response ?? {});
        setAttendanceName(response?.attendance ?? "");
        setSegmentID(response.segment.id);
        setEvaluatedHumanPrompt(humanPrompt);
        setScore(response?.consultantScore ?? null);
        setMessages(response.messages ?? []);
        setPrompts(aIPrompt ?? []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Erro ao recuperar as análises do atendimento!");
        setLoading(false);
        return;
      });
  }, [attendanceId, updateAttendance]);

  useEffect(() => {
    qualityApi
      .get(`/evaluable-events?segmentId=${segmentID}&limit=10000`)
      .then((response) => response.data)
      .then((response) => {
        setEvaluableEvent(response.prompts ?? []);
      })
      .catch(() => {
        toast.error("Erro ao recuperar prompts não processáveis!");
        setLoading(false);
        return;
      });
  }, [segmentID, updateAttendance]);

  useEffect(() => {
    if (segmentID) {
      qualityApi
        .get(`/score/rule?page=1&limit=1000&segmentId=${segmentID}&active=true`)
        .then((response) => {
          const data = response.data.data;
          const promptsPriority = [];
          const promptsMap = {};

          data?.[0]?.scoreRules.forEach((item) => {
            if (
              item.promptId &&
              (!promptsMap[item.promptId] || item.priority)
            ) {
              promptsPriority.push({
                id: item.promptId,
                priority: item.priority,
              });
              promptsMap[item.promptId] = true;
            }
          });

          const evaluableEventPriority = [];
          const evaluableEventMap = {};
          setScoreRule(data?.[0]?.scoreRules);
          data?.[0]?.scoreRules.forEach((item) => {
            if (
              item.evaluableEventId &&
              (!evaluableEventMap[item.evaluableEventId.id] || item.priority)
            ) {
              evaluableEventPriority.push({
                id: item.evaluableEventId.id,
                priority: item.priority,
              });
              evaluableEventMap[item.evaluableEventId.id] = true;
            }
          });
          setPromptsPriority(promptsPriority);
          setEvaluableEventPriority(evaluableEventPriority);
        })
        .catch(() => {
          toast.error("Erro ao recuperar as regras de prioridade!");
        });
    }
  }, [prompts]);

  const deleteAnalysis = (id) => {
    qualityApi
      .delete(`/attendances/analysis/${id}`)
      .then(() => {
        toast.success("Análise deletada com sucesso!");
        setUpdateAttendance(true);
      })
      .catch(() => {
        toast.error("Erro ao deletar análise!");
      });
  };

  useEffect(() => {
    let totalScore = 0;
    let shouldStop = false;

    for (let prompt of prompts) {
      if (shouldStop) break;
      for (let item of prompt.score) {
        if (item.priority && !item.passed) {
          totalScore = 0;
          shouldStop = true;
          break;
        }
        totalScore += item.score;
      }
    }

    if (!shouldStop) {
      for (let evaluableEvent of evaluatedHumanPrompt) {
        if (shouldStop) break;
        for (let item of evaluableEvent.score) {
          if (item.priority && !item.passed) {
            totalScore = 0;
            shouldStop = true;
            break;
          }
          totalScore += item.score;
        }
      }
    }

    setScore(totalScore);
  }, [prompts, evaluatedHumanPrompt]);

  useEffect(() => {
    if (
      prompts &&
      attendanceId &&
      permissions.includes("read:quality:contestation")
    ) {
      qualityApi
        .get(`/contestation/attendance/${attendanceId}`)
        .then((response) => {
          if (response.data.length) {
            setContestation(response.data);
          }
        })
        .catch(() => {
          toast.error("Erro ao recuperar contestações");
        });
    }
  }, [attendanceId, prompts]);

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Atendimento: {attendanceName}</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">Audio</label>
                    <div>
                      {!loading && (
                        <AudioPlayer
                          filename={attendanceName}
                          source={attendance?.source}
                          audio={attendance?.audio}
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup
                    style={{
                      backgroundColor: "aliceblue",
                    }}
                  >
                    <label className="form-control-label">
                      Informações do atendimento
                    </label>
                    <Col className="pl-lg-12">
                      <div>
                        <label className="form-control-label">
                          Segmento:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance?.segment?.title ?? "N/A"}
                          </span>
                        </label>
                        <br />
                        <label className="form-control-label">
                          Processado por:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.user?.username ?? "N/A"}
                          </span>
                        </label>
                        <br />
                        <label className="form-control-label">
                          Supervisor:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.supervisorExternalId ?? "N/A"}
                          </span>
                        </label>
                        <br />
                        <label className="form-control-label">
                          Consultor:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.consultantExternalId ?? "N/A"}
                          </span>
                        </label>
                        <br />
                        <label
                          className="form-control-label"
                          title="Faturado/Possivel"
                        >
                          Nota do consultor:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {score}
                          </span>
                        </label>
                        <br />
                        {permissions?.includes(
                          "create:quality:attendance_review",
                        ) && (
                          <div
                            className="d-flex"
                            style={{
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <label
                              className="form-control-label"
                              style={{
                                margin: 0,
                              }}
                            >
                              Review:
                            </label>
                            <span
                              style={{
                                fontWeight: 300,
                              }}
                            >
                              <ModalAttendanceReview
                                title={hasReview ? "Revisado" : "Pendente"}
                                disabled={hasReview}
                                attendanceId={attendanceId}
                                setUpdateAttendance={setUpdateAttendance}
                                updateAttendance={updateAttendance}
                              />
                            </span>
                            <br />
                          </div>
                        )}
                        {permissions?.includes(
                          "create:quality:user_attendance_feedback",
                        ) && (
                          <div
                            className="d-flex"
                            style={{
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <label
                              className="form-control-label"
                              style={{
                                margin: 0,
                              }}
                            >
                              Feedback:
                            </label>
                            <span
                              style={{
                                fontWeight: 300,
                              }}
                            >
                              <ModalUserAttendanceFeedback
                                title={
                                  hasFeedback
                                    ? "Feedback Aplicado"
                                    : "Aplicar Feedback"
                                }
                                disabled={hasFeedback}
                                consultant={attendance?.consultantExternalId}
                                attendanceId={attendanceId}
                                setUpdateAttendance={setUpdateAttendance}
                                updateAttendance={updateAttendance}
                              />
                            </span>
                            <br />
                          </div>
                        )}
                        {permissions?.includes(
                          "update:quality:contestation_status",
                        ) && (
                          <div
                            className="d-flex"
                            style={{
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            <label
                              className="form-control-label"
                              style={{
                                margin: 0,
                              }}
                            >
                              Itens contestados resolvidos:
                            </label>
                            <span
                              style={{
                                fontWeight: 300,
                              }}
                            >
                              {`${
                                contestation.filter(
                                  (item) => item.status !== "PENDING",
                                ).length
                              } / ${contestation.length}`}
                            </span>
                            <br />
                          </div>
                        )}
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">Transcrição</label>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? <Loading /> : ""}
                      <Table
                        style={{
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <thead className="thead-ligth">
                          <tr>
                            <th scope="col">Mensagens</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messages.map((message, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div
                                    style={{
                                      width: "100%",
                                      whiteSpace: "pre-line",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {message}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <br />
              <Col className="pl-lg-12">
                <div className="table-2 mt-5">
                  <Row>
                    <label
                      className="form-control-label"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Análises IA
                    </label>
                  </Row>
                  <div
                    className="styled-scroll"
                    style={{
                      width: "100%",
                      height: "400px",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {loading ? <Loading /> : ""}
                    <Table
                      style={{
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <thead className="thead-ligth">
                        <tr className="custom-head">
                          <th
                            scope="col"
                            onClick={() =>
                              setOrderPromptsBy([
                                "type",
                                orderPromptsBy[1] === "desc" ? "asc" : "desc",
                              ])
                            }
                            style={{ width: "70px" }}
                          >
                            Tipo{" "}
                            {orderPromptsBy.length &&
                            orderPromptsBy[0] === "type"
                              ? orderPromptsBy[1] === "desc"
                                ? "🠋"
                                : "🠉"
                              : ""}
                          </th>
                          <th
                            scope="col"
                            onClick={() =>
                              setOrderPromptsBy([
                                "prompt",
                                orderPromptsBy[1] === "desc" ? "asc" : "desc",
                              ])
                            }
                          >
                            Prompt{" "}
                            {orderPromptsBy.length &&
                            orderPromptsBy[0] === "prompt"
                              ? orderPromptsBy[1] === "desc"
                                ? "🠋"
                                : "🠉"
                              : ""}
                          </th>
                          <th
                            scope="col"
                            onClick={() =>
                              setOrderPromptsBy([
                                "response",
                                orderPromptsBy[1] === "desc" ? "asc" : "desc",
                              ])
                            }
                          >
                            Resposta{" "}
                            {orderPromptsBy.length &&
                            orderPromptsBy[0] === "response"
                              ? orderPromptsBy[1] === "desc"
                                ? "🠋"
                                : "🠉"
                              : ""}
                          </th>
                          <th
                            scope="col"
                            onClick={() =>
                              setOrderPromptsBy([
                                "checklist",
                                orderPromptsBy[1] === "desc" ? "asc" : "desc",
                              ])
                            }
                          >
                            Checklist{" "}
                            {orderPromptsBy.length &&
                            orderPromptsBy[0] === "checklist"
                              ? orderPromptsBy[1] === "desc"
                                ? "🠋"
                                : "🠉"
                              : ""}
                          </th>
                          <th scope="col">Pesos aplicados</th>
                          <th scope="col">Nota recebida</th>
                          <th
                            scope="col"
                            onClick={() =>
                              setOrderPromptsBy([
                                "date",
                                orderPromptsBy[1] === "desc" ? "asc" : "desc",
                              ])
                            }
                          >
                            Horário de análise{" "}
                            {orderPromptsBy.length &&
                            orderPromptsBy[0] === "date"
                              ? orderPromptsBy[1] === "desc"
                                ? "🠋"
                                : "🠉"
                              : ""}
                          </th>
                          <th scope="col">Classificação</th>
                          {permissions?.includes(
                            "read:quality:contestation",
                          ) && <th scope="col">Contestação</th>}
                          {permissions?.includes(
                            "update:quality:contestation_status",
                          ) && <th scope="col">Regras de score</th>}
                          <th scope="col"> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {prompts
                          .sort((a, b) => {
                            if (orderPromptsBy.length) {
                              let firstData = a;
                              let secondData = b;

                              switch (orderPromptsBy[0]) {
                                case "type":
                                  firstData = a.type;
                                  secondData = b.type;
                                  break;
                                case "prompt":
                                  firstData = a.prompt;
                                  secondData = b.prompt;
                                  break;
                                case "response":
                                  firstData = a.response;
                                  secondData = b.response;
                                  break;
                                case "checklist":
                                  firstData = a.checklist;
                                  secondData = b.checklist;
                                  break;
                                case "price":
                                  firstData = (a.price * 10000).toString();
                                  secondData = (b.price * 10000).toString();
                                  break;
                              }

                              if (orderPromptsBy[0] === "date") {
                                firstData = new Date(a.createdAt);
                                secondData = new Date(b.createdAt);
                                if (orderPromptsBy[1] === "desc") {
                                  return firstData - secondData;
                                }
                                return secondData - firstData;
                              }

                              const questionA = firstData?.replace(
                                questionRegex,
                                "$1",
                              );

                              const questionB = secondData?.replace(
                                questionRegex,
                                "$1",
                              );

                              let numA = questionA.match(/\d+/g);
                              let numB = questionB.match(/\d+/g);

                              numA = numA ? parseInt(numA[0], 10) : 0;
                              numB = numB ? parseInt(numB[0], 10) : 0;

                              if (orderPromptsBy[1] === "desc") {
                                if (numA === numB) {
                                  return questionA.localeCompare(questionB);
                                } else {
                                  return numA - numB;
                                }
                              }
                              if (numA === numB) {
                                return questionB.localeCompare(questionA);
                              } else {
                                return numB - numA;
                              }
                            }

                            return true;
                          })
                          .map((prompt, i) => {
                            const priority = prompt.score.some(
                              (item) => item.priority,
                            );
                            const contestationData = contestation.find(
                              (item) => item.analysisId === prompt.id,
                            );
                            const analysisRules = scoreRule?.filter(
                              (item) => item.promptId === prompt.promptId,
                            );
                            return (
                              <tr key={i} className="custom-tr">
                                <td>{prompt.type}</td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <ModalAttendanceAnalysisPrompt
                                    id={i}
                                    question={prompt.prompt}
                                    title={prompt.title}
                                    prompt={prompt.prompt}
                                  />
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <ModalAttendanceAnalysisResponse
                                    response={prompt.response}
                                  />
                                </td>
                                <td>{prompt.checklist}</td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <ModalPromptScoreDetails
                                    scoreRule={prompt.score}
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {!prompt.score ||
                                  prompt.score.length === 0 ? (
                                    contestationData?.status === "REJECTED" ? (
                                      <CrossIcon
                                        style={{
                                          width: "40px",
                                          height: "46px",
                                        }}
                                      />
                                    ) : (
                                      " "
                                    )
                                  ) : prompt.score.every(
                                      (rule) => rule.passed,
                                    ) ? (
                                    <CheckIcon style={{ width: "30px" }} />
                                  ) : (
                                    <CrossIcon
                                      style={{ width: "30px", height: "46px" }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {new Date(prompt.createdAt).toLocaleString(
                                    "pt-BR",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    },
                                  )}
                                </td>
                                <td>
                                  {priority ? (
                                    <RedFlag style={{ width: "30px" }} />
                                  ) : (
                                    <YellowFlag style={{ width: "30px" }} />
                                  )}
                                </td>
                                <td>
                                  {permissions?.includes(
                                    "read:quality:contestation",
                                  ) &&
                                    (contestationData ? (
                                      <ModalContestationStatus
                                        contestation={contestationData}
                                        updateAttendance={updateAttendance}
                                        setUpdateAttendance={
                                          setUpdateAttendance
                                        }
                                        promptId={prompt.promptId}
                                        analysisId={prompt?.id}
                                        clientId={attendance.client.id}
                                      />
                                    ) : (
                                      permissions?.includes(
                                        "create:quality:contestation",
                                      ) && (
                                        <ModalContestationForm
                                          attendanceId={attendance.id}
                                          analysisId={prompt.id}
                                          segmentId={attendance?.segment?.id}
                                          attendanceName={attendanceName}
                                          response={prompt.response}
                                          updateAttendance={updateAttendance}
                                          setUpdateAttendance={
                                            setUpdateAttendance
                                          }
                                        />
                                      )
                                    ))}
                                </td>
                                {permissions?.includes(
                                  "update:quality:contestation_status",
                                ) && (
                                  <td>
                                    <ModalPromptRulesDetails
                                      scoreRule={analysisRules}
                                    />
                                  </td>
                                )}
                                <td> </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="table 1">
                  <Row>
                    <label
                      className="form-control-label"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      Eventos Avaliáveis
                    </label>
                  </Row>
                  <div
                    className="styled-scroll"
                    style={{
                      width: "100%",
                      height: "400px",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {loading ? <Loading /> : ""}
                    <Table
                      style={{
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <thead className="thead-ligth">
                        <tr className="custom-head">
                          <th scope="col" style={{ width: "70px" }}>
                            Tipo
                          </th>
                          <th scope="col">Evento</th>
                          <th scope="col">Resposta</th>
                          <th scope="col">Checklist</th>
                          <th scope="col">Pesos aplicados</th>
                          <th scope="col">Nota recebida</th>
                          <th scope="col">Horário de análise</th>
                          <th scope="col">Classificação</th>
                          {permissions?.includes(
                            "read:quality:contestation",
                          ) && <th scope="col">Contestação</th>}
                          {permissions?.includes(
                            "update:quality:contestation_status",
                          ) && <th scope="col">Regras de score</th>}
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {evaluableEvent.map((nprompt) => {
                          let promptElement = nprompt;

                          if (
                            evaluatedHumanPrompt.find(
                              (p) => p.evaluableEvent?.id === nprompt.id,
                            )
                          ) {
                            promptElement = evaluatedHumanPrompt.find(
                              (p) => p.evaluableEvent?.id === nprompt.id,
                            );
                            promptElement.evaluated = true;
                          }

                          const priority = evaluableEventPriority.find(
                            (item) => item.id === nprompt?.id && item.priority,
                          );
                          const contestationData = contestation.find(
                            (item) => item.analysisId === promptElement.id,
                          );

                          const analysisRules = scoreRule?.filter(
                            (item) => item?.evaluableEventId?.id === nprompt.id,
                          );

                          return (
                            <tr key={promptElement.id} className="custom-tr">
                              <td>Human</td>
                              <td>
                                <ModalAttendanceAnalysisPrompt
                                  id={promptElement.id}
                                  question={promptElement.prompt}
                                  title={promptElement.title}
                                  prompt={promptElement.prompt}
                                />
                              </td>
                              <td>
                                <ModalAttendanceAnalysisResponse
                                  response={promptElement.response}
                                />
                              </td>
                              <td className="d-flex justify-content-evenly">
                                <div style={{ width: "20px" }}>
                                  {promptElement.checklist}
                                </div>
                              </td>
                              <td>
                                <ModalPromptScoreDetails
                                  scoreRule={promptElement.score}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {!promptElement.score ||
                                promptElement.score.length === 0 ? (
                                  contestationData?.status === "REJECTED" ? (
                                    <CrossIcon
                                      style={{ width: "30px", height: "46px" }}
                                    />
                                  ) : (
                                    " "
                                  )
                                ) : promptElement.score.every(
                                    (rule) => rule.passed,
                                  ) ? (
                                  <CheckIcon style={{ width: "30px" }} />
                                ) : (
                                  <CrossIcon
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {promptElement.evaluated
                                  ? new Date(
                                      promptElement.createdAt,
                                    ).toLocaleString("pt-BR", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : ""}
                              </td>
                              <td>
                                {priority ? (
                                  <RedFlag style={{ width: "30px" }} />
                                ) : (
                                  <YellowFlag style={{ width: "30px" }} />
                                )}
                              </td>
                              <td>
                                {permissions?.includes(
                                  "read:quality:contestation",
                                ) &&
                                  (contestationData ? (
                                    <ModalContestationStatus
                                      contestation={contestationData}
                                      updateAttendance={updateAttendance}
                                      setUpdateAttendance={setUpdateAttendance}
                                      evaluableEventId={
                                        promptElement?.evaluableEvent?.id
                                      }
                                      analysisId={promptElement?.id}
                                      clientId={attendance.client.id}
                                    />
                                  ) : (
                                    permissions.includes(
                                      "create:quality:contestation",
                                    ) && (
                                      <ModalContestationForm
                                        attendanceId={attendance.id}
                                        analysisId={promptElement?.id}
                                        segmentId={attendance?.segment?.id}
                                        attendanceName={attendanceName}
                                        response={promptElement?.response}
                                        updateAttendance={updateAttendance}
                                        setUpdateAttendance={
                                          setUpdateAttendance
                                        }
                                      />
                                    )
                                  ))}
                              </td>
                              {permissions?.includes(
                                "update:quality:contestation_status",
                              ) && (
                                <td>
                                  <ModalPromptRulesDetails
                                    scoreRule={analysisRules}
                                  />
                                </td>
                              )}
                              <td>
                                {promptElement.evaluated ? (
                                  <Row>
                                    <ModalCustomAttendanceAnalysis
                                      attendanceId={attendanceId}
                                      analysisId={promptElement.id}
                                      segmentId={attendance?.segment?.id}
                                      promptId={promptElement.evaluableEvent.id}
                                      evaluated={true}
                                      update={updateAttendance}
                                      setUpdate={setUpdateAttendance}
                                    />
                                    <Button
                                      className="Button"
                                      size="sm"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        margin: "0 5px",
                                      }}
                                      color="danger"
                                      onClick={() =>
                                        deleteAnalysis(promptElement.id)
                                      }
                                    >
                                      <i className="ni ni-fat-remove ni-#ffffff"></i>
                                    </Button>
                                  </Row>
                                ) : (
                                  <ModalCustomAttendanceAnalysis
                                    attendanceId={attendanceId}
                                    segmentId={attendance?.segment?.id}
                                    promptId={promptElement.id}
                                    evaluated={false}
                                    update={updateAttendance}
                                    setUpdate={setUpdateAttendance}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default QualityAttendance;
